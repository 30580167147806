let CognitoRegion = 'ap-northeast-1';
let CognitoUserPool = 'ap-northeast-1_Lx4RhvYkv'; //dev用
// let CognitoUserPool = 'ap-northeast-1_tzQ9aOidb'; 
let CognitoUserPoolClient = '732ii710hqff125d5q890hj65'; //dev用
// let CognitoUserPoolClient = '19rvb8edvpv27qufpnhenfbelc';

let CognitoDomainPrefix = 'service-dev-user';  //dev用
// let CognitoDomainPrefix = 'service-user-test';

let amplifyConfig = {
    Auth: {
        region: CognitoRegion,
        userPoolId: CognitoUserPool,
        userPoolWebClientId: CognitoUserPoolClient,
        oauth: {
            domain: `${CognitoDomainPrefix}.auth.${CognitoRegion}.amazoncognito.com`,
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'https://myaccount.service.sandbox.blender-melco.com',
            redirectSignOut: 'https://myaccount.service.sandbox.blender-melco.com/logOut',
            // redirectSignIn: 'https://myaccount.service.lite.blender-demo.com',
            // redirectSignOut: 'https://myaccount.service.lite.blender-demo.com/logOut',
            responseType: ['token']
        },
        "federationTarget": "COGNITO_USER_POOLS"
    },
    API: {  
        endpoints: [
          {
            name: 'api_url_base',
            endpoint: 'https://bx2474j063.execute-api.ap-northeast-1.amazonaws.com/dev', //dev用
            // endpoint: 'https://kc08vin1wc.execute-api.ap-northeast-1.amazonaws.com/poc',
            custom_header: async () => { 
              return { Authorization : 'token' } 
           }
          }
        ]
      }
};
export default amplifyConfig;